<template>
  <div id="contact-section">
    <div class="left">
      <!-- <div class="super-title">{{$t('homepage.contactUs')}}</div> -->
      <div class="title homepage-section-title">{{$t('homepage.contactUs')}}</div>
      <div class="input-group">
        <input
          class="email"
          :class="{'error': invalid.email}"
          v-model="form.email"
          :placeholder="placeholderTranslations.email"
          type="text"
          @input="invalid.email = false"
          required
        />
      </div>
      <div class="input-group">
        <textarea
          class="message"
          :class="{'error': invalid.message}"
          v-model="form.message"
          :placeholder="placeholderTranslations.message"
          type="text"
          @input="invalid.message = false"
          required
        />
      </div>
      <!-- <div
        class="title-desc"
      >Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ip...</div>-->
      <button class="acc" @click="sendMessage">{{$t('homepage.sendMessage')}}</button>
    </div>
    <div class="right">
      <div class="img">
        <img src="@/assets/image/homepage/contact.svg" alt="intro-placeholder" draggable="false" />
      </div>
    </div>
  </div>
</template>

<script>
import formMixin from '@/assets/js/formMixin.js';
import { mapActions } from 'vuex';
export default {
  mixins: [formMixin],
  data () {
    return {
      form: {
        email: '',
        message: '',
      },
      invalid: {
        email: false,
        message: false,
      },
    };
  },
  methods: {
    ...mapActions({
      postMessage: 'postMessage',
    }),
    async sendMessage () {
      const emailRegexPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const regexTest = new RegExp(emailRegexPattern, 'g').test(this.form.email);
      if (!regexTest) {
        this.invalid.email = true;
      } else if (!this.form.message) {
        this.invalid.message = true;
      } else {
        const params = { ...this.form };
        try {
          const res = await this.postMessage(params);
          this.showSuccessMsg(this.$i18n.t('message.messageSentSuccess'));

          // Reset form data
          this.form = {
            email: '',
            message: '',
          };
        } catch (err) {
          this.errorHelperMixin.parsePrintErrorMsg(err);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#contact-section {
  background: #ffffff;
  // background: $intro-back;
  min-height: 70vh;
  display: flex;
  flex-wrap: wrap;
  gap: 50px 60px;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  margin-top: calc(#{$mob-nav-height} + #{$mob-nav-padding-y} * 2);
  position: relative;

  > img {
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0.5;
  }

  @media screen and (min-width: 768px) {
    padding: 40px 20px;
    margin-top: 0;
    padding-top: calc(#{$full-nav-height});
    // margin-top: calc(#{$full-nav-height});
  }

  .left {
    $max-wid: 400px;
    max-width: $max-wid;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px 10px;

    > .super-title {
      color: $accent-color;
      font-weight: 500;
    }

    > .title {
      margin: 0 0 30px 0;
      font-weight: bold;
    }

    > .title-desc {
      color: $light-text;
      font-family: 'Mulish';
      line-height: 34px;
    }

    input,
    textarea {
      max-width: $max-wid;
      width: 100%;

      &.error {
        border: 1px solid $error-color;
      }
    }

    textarea {
      height: 120px;
    }

    @media screen and (min-width: 768px) {
      margin: 0 100px 0 0;
    }

    button {
      margin: 50px auto 0;
      width: 208px;

      &.alt {
        border: 2px solid;
        border-color: $accent-color;
      }

      @media screen and(min-width: 768px) {
        margin: 50px 0 0 0;
      }
    }
  }

  .right {
    img {
      width: 100%;
    }
  }
}
</style>

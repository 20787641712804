<template>
  <div id="home-page" :class="{'other-page': isOtherPage}">
    <header :style="headerStyle">
      <full-nav v-if="!isMobileView"></full-nav>
      <mob-nav v-if="isMobileView"></mob-nav>
    </header>
    <div class="content" ref="mainContent">
      <section-intro class="intro-page-trg"></section-intro>
      <section-benefits></section-benefits>
      <section-try-app></section-try-app>
      <section-suitable-for></section-suitable-for>
      <section-contact></section-contact>
      <!-- <section-packages></section-packages> -->
    </div>
    <footer id="contact-section">
      <full-footer v-if="!isMobileView"></full-footer>
      <mob-footer v-if="isMobileView"></mob-footer>
    </footer>
  </div>
</template>

<script>
import FullNav from '@/components/Header/FullNav.vue';
import MobNav from '@/components/Header/MobNav.vue';
import FullFooter from '@/components/Footer/FullFooter.vue';
import MobFooter from '@/components/Footer/MobFooter.vue';
import SectionIntro from '@/components/Homepage/SectionIntro.vue';
import SectionBenefits from '@/components/Homepage/SectionBenefits.vue';
import SectionTryApp from '@/components/Homepage/SectionTryApp.vue';
import SectionSuitableFor from '@/components/Homepage/SectionSuitableFor.vue';
import SectionContact from '@/components/Homepage/SectionContact.vue';
// import SectionPackages from '@/components/Homepage/SectionPackages.vue'
export default {
  name: 'Home',
  components: {
    FullNav,
    MobNav,
    FullFooter,
    MobFooter,
    SectionIntro,
    SectionBenefits,
    SectionTryApp,
    SectionSuitableFor,
    SectionContact,
    // SectionPackages
  },
  data () {
    return {
      isOtherPage: false,
    };
  },
  computed: {
    headerStyle () {
      if (this.isMobileView) {
        return {
          'z-index': 100,
          position: 'fixed',
        };
      }
      return {};
    },
  },
  mounted () {
    this.setupScrollIntersection();
  },
  methods: {
    setupScrollIntersection () {
      const options = {
        root: null,
        rootMargin: '0px',
        // threshold: 1.0
      };

      const callback = (entries) => {
        entries.forEach(entry => {
          this.isOtherPage = !entry.isIntersecting;
        });
      };

      const observer = new IntersectionObserver(callback, options);
      const introPage = document.querySelector('.intro-page-trg');
      observer.observe(introPage);
    },
  },
};
</script>
<style lang="scss" scoped>
#home-page {
  height: 100%;
  background: $intro-back;
  color: $homepage-color;

  // header {}
  // footer {}
}
</style>

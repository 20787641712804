<template>
  <div id="suitable-section">
    <div class="left">
      <div class="super-title">{{$t('homepage.applicationIs')}}</div>
      <div class="title homepage-section-title">{{$t('homepage.suitableForAll')}}</div>
      <div class="list">
        <ul>
          <li v-for="(item,index) in suitableForList" :key="index">
            <!-- <img src alt /> -->
            <svg
              width="10"
              height="12"
              viewBox="0 0 10 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 4.26795C10.3333 5.03775 10.3333 6.96225 9 7.73205L3 11.1962C1.66667 11.966 3.85264e-07 11.0037 4.52563e-07 9.4641L7.55404e-07 2.5359C8.22702e-07 0.996296 1.66667 0.0340472 3 0.803847L9 4.26795Z"
                :fill="item.color"
              />
            </svg>
            <span>{{item.label}}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="right">
      <div class="img">
        <!-- <img src="@/assets/image/homepage/gym_placeholder.png" alt="gym-placeholder" /> -->
        <img
          src="@/assets/image/homepage/gym_placeholder.svg"
          alt="gym-placeholder"
          draggable="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    suitableForList () {
      return [
        {
          label: this.$i18n.t('homepage.suitableFor.danceSchool'),
          color: '#F06259',
        },
        {
          label: this.$i18n.t('homepage.suitableFor.sportClub'),
          color: '#FFE27C',
        },
        {
          label: this.$i18n.t('homepage.suitableFor.gyms'),
          color: '#F06259',
        },
        {
          label: this.$i18n.t('homepage.suitableFor.associations'),
          color: '#FFE27C',
        },
        {
          label: this.$i18n.t('homepage.suitableFor.allOrganizations'),
          color: '#F06259',
        }
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
#suitable-section {
  background: #ffffff;
  min-height: 60vh;
  display: flex;
  gap: 50px 100px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;

  @media screen and (min-width: 768px) {
    padding: 40px 20px;
  }

  .left {
    .super-title {
      color: $accent-color;
      font-weight: bold;
    }

    > .title {
      margin-bottom: 100px;
      color: $global-text;
    }

    .list {
      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-family: 'Mulish';

        li {
          display: flex;
          align-items: center;
          column-gap: 5px;
          color: $light-text;
          margin-bottom: 20px;

          > span {
            margin-left: 10px;
          }

          // &:before {
          //   content: "";
          //   border-color: #0000 #f17569;
          //   border-style: solid;
          //   border-width: 0.35em 0 0.35em 0.45em;
          //   display: block;
          //   height: 0;
          //   position: relative;
          //   width: 0;
          // }

          // img {
          //   $img-size: 10px;
          //   width: $img-size;
          //   height: $img-size;
          // }
        }
      }
    }
  }

  .right {
    img {
      width: 100%;
    }
  }

  .polygon {
    width: 16px;
    height: 16px;

    background: #ffe27c;
    border-radius: 2px;
    transform: rotate(90deg);
  }
}
</style>

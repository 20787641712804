<template>
  <div id="home-section">
    <img src="@/assets/image/homepage/section1.png" alt draggable="false" v-if="!isMobileView" />
    <div class="left">
      <div class="super-title">{{$t('homepage.intro.easyWay')}}</div>
      <div class="title homepage-section-title">{{$t('homepage.intro.stayInTrack')}}</div>
      <div class="title-desc">{{$t('homepage.intro.text')}}</div>
      <!-- <button class="alt">{{$t('homepage.tryFree')}}</button> -->
    </div>
    <div class="right">
      <div class="img">
        <img
          src="@/assets/image/homepage/something_cool.svg"
          alt="intro-placeholder"
          draggable="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
#home-section {
  background: $intro-back;
  min-height: 70vh;
  display: flex;
  flex-wrap: wrap;
  gap: 50px 60px;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  margin-top: calc(#{$mob-nav-height} + #{$mob-nav-padding-y} * 2);
  position: relative;

  > img {
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0.5;
  }

  @media screen and (min-width: 768px) {
    padding: 40px 20px;
    margin-top: 0;
    padding-top: calc(#{$full-nav-height});
    // margin-top: calc(#{$full-nav-height});
  }

  .left {
    max-width: 400px;

    > .super-title {
      color: $accent-color;
      font-weight: 500;
    }

    > .title {
      margin: 0 0 30px 0;
      font-weight: bold;
      text-transform: uppercase;
    }

    > .title-desc {
      color: $light-text;
      font-family: 'Mulish';
      line-height: 34px;
    }

    @media screen and (min-width: 768px) {
      margin: 0 100px 0 0;
    }

    button {
      margin: 50px auto 0;
      width: 208px;

      &.alt {
        border: 2px solid;
        border-color: $accent-color;
      }

      @media screen and(min-width: 768px) {
        margin: 50px 0 0 0;
      }
    }
  }

  .right {
    img {
      width: 100%;
    }
  }
}
</style>

<template>
  <div id="benefits-section">
    <img src="@/assets/image/homepage/section2.png" alt v-if="!isMobileView" draggable="false" />
    <!-- <div class="super-title">Benefiti</div> -->
    <div class="title homepage-section-title">{{$t('homepage.benefits.title')}}</div>
    <div class="packages">
      <div class="starter card">
        <div class="img">
          <img src="@/assets/image/homepage/docs1.svg" alt="docs" draggable="false" />
        </div>
        <div class="title">{{$t('homepage.benefits.fewClicks')}}</div>
        <div class="desc">{{$t('homepage.benefits.fewClicksText')}}</div>
      </div>
      <div class="optimum card">
        <div class="img">
          <img src="@/assets/image/homepage/docs2.svg" alt="docs2" draggable="false" />
        </div>
        <div class="title">{{$t('homepage.benefits.accessible')}}</div>
        <div class="desc">{{$t('homepage.benefits.accessibleText')}}</div>
      </div>
      <div class="master card">
        <div class="img">
          <img src="@/assets/image/homepage/docs3.svg" alt="devices" draggable="false" />
        </div>
        <div class="title">{{$t('homepage.benefits.friendly')}}</div>
        <div class="desc">{{$t('homepage.benefits.friendlyText')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
#benefits-section {
  background: #ffffff;
  min-height: 70vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  position: relative;

  > img {
    position: absolute;
    right: 0;
    bottom: -120px;
    opacity: 0.5;
  }

  @media screen and (min-width: 768px) {
    padding: 40px 20px;
  }

  > .title {
    margin: 0 0 60px 0;
  }

  .packages {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    row-gap: 30px;
    column-gap: 60px;
    margin: 0 0 0 0;

    .card {
      $size: 310px;
      background-color: white;
      box-shadow: $global-shadow-alt;
      border-radius: 40px;
      padding: 30px;
      min-width: calc(#{$size} - 20px);
      min-height: $size;
      display: flex;
      gap: 20px;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      transition: transform 0.3s ease;
      outline: none;
      cursor: pointer;

      @media screen and (min-width: 768px) {
        // width: calc(#{$size} + 20px);
        width: $size;

        &:hover {
          transform: translateY(20px);
        }
      }

      .title {
        font-size: 20px;
        font-weight: bold;
      }

      .desc {
        color: gray;
        text-align: center;
      }
    }
  }
}
</style>

<template>
  <div id="full-nav">
    <router-link to="/">
      <div class="logo">
        <img
          src="@/assets/image/logo.png"
          alt="LOGO"
          draggable="false"
        />
      </div>
    </router-link>
    <ul class="nav">
      <li
        v-for="(item,index) in homepageNavItems"
        :key="index"
      >
        <router-link :to="{name: 'HomePage', hash: `#${item.route}`}">
          <span>{{item.label}}</span>
        </router-link>
      </li>
      <li key='instructions'>
        <a
          :href="manualLink"
          target="_blank"
        >
          <span>{{$t('app.manual')}}</span>
        </a>
      </li>
    </ul>
    <div class="right">
      <div class="auth">
        <router-link :to="{name: 'LoginPage'}">
          <button class="login acc mini">{{$t('auth.login')}}</button>
        </router-link>
        <router-link :to="{name: 'RegisterPage'}">
          <button class="register trans mini">{{$t('auth.register')}}</button>
        </router-link>
      </div>
      <lang-changer />
    </div>
  </div>
</template>

<script>
import LangChanger from '@/components/Shared/LangChanger/LangChanger.vue';
import { mapGetters } from 'vuex';
const TIMEOUT = 1;
export default {
  components: {
    LangChanger,
  },
  computed: {
    ...mapGetters({
      homepageNavItems: 'homepageNavItems',
    }),
  },
  watch: {
    $route (val) {
      this.scrollToHash(val.hash, 'smooth');
    },
  },
  mounted () {
    this.scrollToHash(this.$route.hash);
  },
  methods: {
    scrollToHash (hashtag, scrollBehavior = 'auto') {
      if (hashtag) {
        setTimeout(() => {
          const elName = `${hashtag}-section`;
          const el = document.querySelector(elName);
          if (el) {
            window.scroll({
              top: el.offsetTop,
              behavior: scrollBehavior,
            });
          }
          // Not smooth scroll [native]
          // location.href = hashtag
        }, TIMEOUT);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#full-nav {
  @include full-nav-bar;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(4px);
}
</style>
<style lang="scss">
.other-page {
  #full-nav {
    background: rgba(77, 77, 77, 0.198);
  }
}
</style>

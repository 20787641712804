<template>
  <div id="try-section">
    <!-- <div class="super-title"></div> -->
    <div class="title homepage-section-title">{{$t('homepage.tryAppFree')}}</div>
    <div class="title-desc">{{$t('homepage.tryAppText')}}</div>
    <router-link :to="{name: 'RegisterPage'}">
      <button>{{$t('homepage.tryFree')}}</button>
    </router-link>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
#try-section {
  background: linear-gradient(89.56deg, #ee7b56 -1.9%, #f1756d 102.96%);
  min-height: 30vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  color: white;

  @media screen and (min-width: 768px) {
    padding: 40px 20px;
  }

  .title {
    text-align: center;
  }

  .title-desc {
    margin-bottom: 85px;
    text-align: center;
    max-width: 900px;
  }

  > * {
    margin-top: 10px;
  }

  button {
    width: 208px;
  }
}
</style>

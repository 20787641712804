<template>
  <div
    id="mob-nav"
    :class="{active: isOpenBar}"
  >
    <div class="top-bar">
      <router-link to="/">
        <div class="logo">
          <img
            src="@/assets/image/logo_white.png"
            alt="LOGO"
            draggable="false"
          />
        </div>
      </router-link>
      <div
        class="menu-opener"
        @click="isOpenBar = !isOpenBar"
      >
        <i
          class="fa fa-bars"
          v-if="!isOpenBar"
        ></i>
        <i
          class="fa fa-times"
          v-if="isOpenBar"
        ></i>
      </div>
    </div>
    <div
      class="menu"
      v-if="isOpenBar"
    >
      <transition-group
        name="slide-left"
        tag="ul"
        class="nav"
        appear
      >
        <li
          v-for="item in filteredNavItems"
          :key="item.id"
        >
          <router-link :to="{name: 'HomePage', hash: `#${item.route}`}">
            <span>{{item.label}}</span>
          </router-link>
        </li>
        <li key='instructions'>
          <a
            :href="manualLink"
            target="_blank"
          >
            <span>{{$t('app.manual')}}</span>
          </a>
        </li>
      </transition-group>
      <div class="bottom">
        <div class="auth">
          <router-link :to="{name: 'LoginPage'}">
            <button class="login mini">{{$t('auth.login')}}</button>
          </router-link>
          <router-link :to="{name: 'RegisterPage'}">
            <button class="register mini">{{$t('auth.register')}}</button>
          </router-link>
        </div>
        <lang-changer />
      </div>
    </div>
    <transition name="fade">
      <div
        class="mob-overlay"
        v-if="isOpenBar"
        @click="isOpenBar = false"
      ></div>
    </transition>
  </div>
</template>

<script>
import LangChanger from '@/components/Shared/LangChanger/LangChanger.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    LangChanger,
  },
  data () {
    return {
      isOpenBar: false,
    };
  },
  computed: {
    ...mapGetters({
      filteredNavItems: 'homepageNavItems',
    }),
  },
};
</script>

<style lang="scss" scoped>
#mob-nav {
  @include mob-nav-bar;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}
</style>
